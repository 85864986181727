import React from 'react';
import Layout from '../../components/Layout';
import SectionIntro from '../../components/SectionIntro';
import ContactForm from '../../components/ContactForm';
import Seo from '../../components/SEO';
import {graphql, useStaticQuery} from 'gatsby';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {getImage} from 'gatsby-plugin-image';

const DemoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);

  const {backgroundImage} = data;
  const bgImage = convertToBgImage(getImage(backgroundImage));
  return (
    <Layout
      seo={
        <Seo
          title="Get a Demo | Trustle"
          description="Tell us about yourself and we'll connect you with an a member of our Team who can share more about the product."
        />
      }
      className="md:pb-16"
    >
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <section className="py-16 pb-0 md:pt-30 ">
          <div className="max-w-5xl pt-0 mx-5 text-center text-white lg:mx-auto md:mx-8">
            <SectionIntro
              isPageHeader
              title="Schedule a Demo"
              description="Tell us about yourself and we'll connect you with an a member of our Team who can share more about the product."
            />
          </div>
        </section>

        <section className="flex flex-col items-center justify-between xl:flex-row max-w-[1360px] mx-auto mb-16 md:mb-32">
          <div className="w-full mb-16 xl:mb-0 ">
            <ContactForm />
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default DemoPage;
